


































































































































































import { Component, Prop } from "vue-property-decorator";
import { getComponent, getConfigEnv } from "@/utils/helpers";
import TasqJob from "../../interfaces/tasqs/TasqJob";
import { mixins } from "vue-class-component";
import DataLoading from "../../lib/mixins/dataLoading";
import setpointModule from "@/store/modules/setpointModule";
import defermentLabelingModule from "@/store/modules/defermentLabelingModule";
import { CHART_CLICK_EVENT } from "@/lib/constants";
import tasqProductionDataChartModule from "@/store/modules/tasqProductionDataChartModule";
import accountModule from "@/store/modules/accountModule";
import tasqsListModule from "@/store/modules/tasqsListModule";

@Component({
  components: {
    SpinnerLoader: () => getComponent("loaders/SpinnerLoader"),
    LineChart: () => import("@/lib/charts/lineChart")
  }
})
export default class DefermentLabeling extends mixins(DataLoading) {
  @Prop({ required: true }) tasq!: TasqJob;

	showTrainingDefermentModelProgress = false;
	isLoadingTrainingData = false


  get productionData() {
    return tasqProductionDataChartModule.productionData;
  }

  get productionDataDefermentLabeling() {
    return tasqProductionDataChartModule.productionDataDefermentLabeling;
  }

  get productionDataDict() {
    return tasqProductionDataChartModule.productionDataDict;
  }

  get defermentLabelingData() {
    return defermentLabelingModule.chartLabelingData;
  }

  get updatePredictionData() {
    return defermentLabelingModule.datasetForPrediction;
  }

  previewUpdate() {

  }


  sleep(ms) {
	return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async animateDefermentLabelProgressBar(seconds) {
	  if (!seconds) {
		  seconds = 60
	  }
	  
	  this.showTrainingDefermentModelProgress = true
	  var microseconds = (defermentLabelingModule.defermentModelCalculatingETA + 15) * 100
	  var previous_eta = defermentLabelingModule.defermentModelCalculatingETA
	  for (var x = 1; x < microseconds; x++) {
		  // @ts-ignore
			this.$refs.progressBarID.style.width = ((x / microseconds) * 100).toString() + "%"
			// @ts-ignore
			this.$refs.progressBarStringID.innerHTML = "Training... " + Math.round((x / microseconds) * 100).toString() + "%"
			
			await this.sleep(1)

			// if (defermentLabelingModule.defermentModelCalculatingETA != null && defermentLabelingModule.defermentModelCalculatingETA != previous_eta) {
			// 	// var percent_complete = x / microseconds
			// 	previous_eta = defermentLabelingModule.defermentModelCalculatingETA
			// 	var offset = microseconds - ((defermentLabelingModule.defermentModelCalculatingETA * 100) + x)

			// 	microseconds = ((defermentLabelingModule.defermentModelCalculatingETA * 100) + x)
				
			// 	// x = Math.round(microseconds * percent_complete)
			// }
			
	  }
	  this.showTrainingDefermentModelProgress = false
  }

  selectedDates = [] as any;

  chartData = {
    datasets: [
      {
        label: "",
        data: [],
        borderColor: "#2CE6C2",
        backgroundColor: "rgba(44,230,194,0.09)",
        tension: 0,
        fill: false,
        pointRadius: 5,
        pointHitRadius: 5,
        pointHoverRadius: 5,
        pointBorderColor: "#2CE6C2",
        // pointHoverBackgroundColor: '#2CE6C2',
        // pointHoverBorderColor: '#2CE6C2',
        pointBackgroundColor: [] as any,
        borderWidth: 1.5,
        spanGaps: true
      },
      {
        label: 'Target upper band',
        data: [],
        borderColor: 'rgba(44,230,194,0.1)',
        tension: 0,
        borderWidth: 2,
        fill: false,
        pointRadius: 0,
        pointHitRadius: 5,
        pointHoverBorderColor: 'transparent',
        pointHoverBackgroundColor: 'transparent',
        yAxisID: 'y-axis-0',
        spanGaps: true,
        unit: '',
      },
      {
        label: 'Target lower band',
        data: [],
        borderColor: 'transparent',
        backgroundColor: 'rgba(44,230,194,0.25)',
        tension: 0,
        borderWidth: 2,
        fill: '-1',
        pointRadius: 0,
        pointHitRadius: 5,
        pointHoverBorderColor: 'transparent',
        pointHoverBackgroundColor: 'transparent',
        yAxisID: 'y-axis-0',
        spanGaps: true,
        unit: '',
      },
	  {
        label: 'Target',
        data: [],
        borderColor: '#2CE6C2',
        backgroundColor: 'transparent',
        tension: 0,
        borderWidth: 2,
        fill: true,
        borderDash: [9, 8],
        pointRadius: 0,
        pointHitRadius: 5,
        pointHoverBorderColor: '#ffffff',
        pointHoverBackgroundColor: '#FFFFFF',
        yAxisID: 'y-axis-0',
        spanGaps: true,
        unit: '',
      },
      {
        label: 'Preview upper band',
        data: [],
        borderColor: 'rgba(244,130,94,0.2)',
        tension: 0,
        borderWidth: 2,
        fill: false,
        pointRadius: 0,
        pointHitRadius: 5,
        pointHoverBorderColor: 'transparent',
        pointHoverBackgroundColor: 'transparent',
        yAxisID: 'y-axis-0',
        spanGaps: true,
        unit: '',
      },
      {
        label: 'Preview lower band',
        data: [],
        borderColor: 'transparent',
        backgroundColor: 'rgba(244,130,94,0.35)',
        tension: 0,
        borderWidth: 2,
        fill: '-1',
        pointRadius: 0,
        pointHitRadius: 5,
        pointHoverBorderColor: 'transparent',
        pointHoverBackgroundColor: 'transparent',
        yAxisID: 'y-axis-0',
        spanGaps: true,
        unit: '',
      },
      {
        label: 'Target preview',
        data: [],
        borderColor: '#e37a4d',
        backgroundColor: 'transparent',
        tension: 0,
        borderWidth: 2,
        fill: true,
        borderDash: [9, 8],
        pointRadius: 0,
        pointHitRadius: 5,
        pointHoverBorderColor: '#ffffff',
        pointHoverBackgroundColor: '#FFFFFF',
        yAxisID: 'y-axis-0',
        spanGaps: true,
        unit: '',
      },
    ],
    labels: []
  };

  chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        fontColor: "rgba(255,255,255,0.7)",
      }
    },
    animation: {
      duration: 500
    },
    scales: {
      xAxes: [
        {
          type: "time",
          gridLines: {
            display: false
          },
          ticks: {
            fontColor: "#ffffff90",
            fontStyle: "bold",
            maxTicksLimit: 8,
            minRotation: 50
          },
          time: {
            unit: "day",
            displayFormats: {
              day: "MM/DD"
            }
          }
        }
      ],
      yAxes: [
        {
          position: "left",
          type: "linear",
          gridLines: {
            color: "rgba(255, 255, 255, 0.3)"
          },
          ticks: {
            fontColor: "#ffffff90",
            maxTicksLimit: 4,
            fontStyle: "bold",
            min: 0
          }
        }
      ]
    },
    tooltips: {
      enabled: false
    },
    onClick: evt => {
      const chart = (this.$refs.defermentChart as any).$data._chart;
      const item = chart.getElementAtEvent(evt);
      if (item[0]) {
        const dateIndex = item[0]._index;
        const date = this.chartData.labels[dateIndex];
        this.toggleDateOnChart(date);
      }
    },
    annotation: {
      annotations: []
    }
  };

  async created() {
    this.startDataLoading();
    console.log(this.productionDataDefermentLabeling)
    if (!this.productionDataDefermentLabeling.loaded) {
      await tasqProductionDataChartModule.getProductionData(90);
	  await tasqProductionDataChartModule.getProductionDataDefermentLabeling(90);
    }


// // Oil rate is 0
// // Water rate is 1
// // Gas rate is 2

// 	if (tasqProductionDataChartModule.defermentType == "oil_rate") {
// 		this.chartData.datasets[3].yAxisID = 'y-axis-1';
// 		this.chartData.datasets[4].yAxisID = 'y-axis-1';
// 		this.chartData.datasets[5].yAxisID = 'y-axis-1';
// 	}



	
    await this.getChartData();
    console.log(this.productionDataDefermentLabeling)
	if(getConfigEnv('OPERATOR_LOWERCASED') !== 'swn'){
		this.chartData.datasets[0].data = this.productionDataDefermentLabeling.data[0];
		this.chartData.datasets[0].label = "Oil rate"

		this.chartData.datasets[0].borderColor = "#2CE6C2"
		// this.chartData.datasets[0].backgroundColor = "#2CE6C2"


		this.chartData.datasets[1].borderColor = "rgba(44,230,194,0.1)" // Target upper band


		this.chartData.datasets[2].borderColor = "transparent" // Target lower band
		this.chartData.datasets[2].backgroundColor = "rgba(44,230,194,0.25)" // Target lower band

		this.chartData.datasets[3].borderColor = "#2CE6C2" // Dashed target band
		// this.chartData.datasets[3].backgroundColor = "rgba(44,230,194,0.09)" // Dashed target band



	} else {
		this.chartData.datasets[0].data = this.productionDataDefermentLabeling.data[2];
		this.chartData.datasets[0].label = "Gas rate"

		this.chartData.datasets[0].borderColor = "#f55d8b"
		// this.chartData.datasets[0].backgroundColor = "#f55d8b"



		this.chartData.datasets[1].borderColor = "rgba(245, 93, 139,0.1)" // Target upper band


		this.chartData.datasets[2].borderColor = "transparent" // Target lower band
		this.chartData.datasets[2].backgroundColor = "rgba(245, 93, 139,0.25)" // Target lower band

		this.chartData.datasets[3].borderColor = "#f55d8b" // Dashed target band
		// this.chartData.datasets[3].backgroundColor = "rgba(245, 93, 139,0.09)" // Dashed target band




	}


  console.log( this.productionDataDefermentLabeling.data)
    
	this.chartData.datasets[1].data = this.productionDataDefermentLabeling.data[3]; 
	this.chartData.datasets[2].data = this.productionDataDefermentLabeling.data[5]; 
	this.chartData.datasets[3].data = this.productionDataDefermentLabeling.data[4]; 
    this.chartData.labels = this.productionDataDefermentLabeling.time;
    const mappedDataset = this.defermentLabelingData.dataset
      .filter(i => i.Include)
      .map(i => i.date);
    this.chartData.datasets[0].pointBackgroundColor = this.chartData.labels.map(i =>
      mappedDataset.includes(i) ? "#2CE6C2" : "transparent"
    );

if(getConfigEnv('OPERATOR_LOWERCASED') !== 'swn'){
   this.chartData.datasets[1].data = this.productionDataDefermentLabeling.data[6]; 
	this.chartData.datasets[2].data = this.productionDataDefermentLabeling.data[8]; 
	this.chartData.datasets[3].data = this.productionDataDefermentLabeling.data[7]; 
}

    
    this.stopDataLoading();
    setTimeout(() => {
      this.enableDragging();
    }, 1000);
  }

  async getChartData() {
    await defermentLabelingModule.getChartLabelingData();
  }

  async runDefermentCalc(auto_confirm) {

	this.isLoadingTrainingData = true
    // this.startDataLoading();
    const mappedLabels = this.selectedDates.map(i => ({
      date: i,
      OperatorNodeID: `${getConfigEnv("OPERATOR_PASCALED")}##${this.tasq.wellName}`,
      Include: true,
      key: `${getConfigEnv("OPERATOR_PASCALED")}##${this.tasq.wellName}##${this.tasq.defermentType}`,
      Operator: getConfigEnv("OPERATOR_PASCALED"),
      ModifiedBy: accountModule.user.email
    }));

	if (auto_confirm) {
		this.startDataLoading();
	}


    await defermentLabelingModule.runDefermentCalc({
	  autoConfirm: auto_confirm,
      mappedLabelsJson: mappedLabels,
      dataJson: JSON.stringify(this.productionDataDict),
	  modifiedBy: accountModule.user.email
    });

	if (auto_confirm) {
		this.stopDataLoading();
		this.$emit("update-prediction-finished");
		return
	}


	this.animateDefermentLabelProgressBar(defermentLabelingModule.defermentModelCalculatingETA)
	
	var newDataset = false;
	var count = 0;
	var max_count = 75; // Wait 5 min max for a response
	// this.startDataLoading();
	while (!newDataset) {
		await defermentLabelingModule.getDefermentCalcState({
			id: defermentLabelingModule.defermentModelCalculatingID,
			modifiedby: accountModule.user.email
		})
		if ((defermentLabelingModule.newChartLabelingData != null && defermentLabelingModule.newChartLabelingData.length > 0) || count > max_count) {
			if (!(defermentLabelingModule.newChartLabelingData != null && defermentLabelingModule.newChartLabelingData.length > 0)) {
				// Failed to get data after 5 min
				console.log("Failed to retrieve data")
			} else {
				this.addNewDefermentLabelsToChart()
			}
			this.isLoadingTrainingData = false
			break
		}
		count += 1;
		await this.sleep(4000)
	}

    // this.stopDataLoading();
  }


  

  async addNewDefermentLabelsToChart() {
	  this.startDataLoading();
	  await this.sleep(1)

	  var fillUpToIndex = 0
	  for (var y = 0; y < this.chartData.labels.length; y++) {
		  if (this.chartData.labels[y] == defermentLabelingModule.newChartLabelingData[0].date) {

			  fillUpToIndex = y
			  break
		  }
	  }

	  var newDataUpperThreshold: any[] = []
	  var newDataLowerThreshold: any[] = []
	  var newDataReferenceData: any[] = []
	  for (var r = 0; r < fillUpToIndex; r++) {
		  newDataUpperThreshold.push(null)
		  newDataLowerThreshold.push(null)
		  newDataReferenceData.push(null)
	  }


	  for (var x = 0; x < defermentLabelingModule.newChartLabelingData.length; x++) {
		  newDataReferenceData.push(defermentLabelingModule.newChartLabelingData[x].Reference)
		  newDataLowerThreshold.push(defermentLabelingModule.newChartLabelingData[x].lower_thresh)
		  newDataUpperThreshold.push(defermentLabelingModule.newChartLabelingData[x].upper_thresh)
	  }


	//   newDataUpperThreshold.push(...b)
		// @ts-ignore
	  this.chartData.datasets[6].data = newDataReferenceData
		// @ts-ignore
	  this.chartData.datasets[4].data = newDataUpperThreshold // [null,null,null,null,null,null,null,null,null,null,null,null,3,4,16,14,13,8,7,9,3,3,4,16,14,13,8,7,9,3,3,4,16,14,13,8,7,9,3,3,4,16,14,13,8,7,9,3,3,4,16,14,13,8,7,9];
	  // @ts-ignore
	  this.chartData.datasets[5].data = newDataLowerThreshold // [null,null,null,null,null,null,null,null,null,null,null,null,1,1,5,8,4,3,2,4,1,1,1,1,5,8,4,3,2,4,1,1,1,1,5,8,4,3,2,4,1,1,1,1,5,8,4,3,2,4,1,1,1,1,5,8,4,3,2,4,];
	//   this.chartData.datasets[1].data = this.productionDataDefermentLabeling.data[2];
	//   this.chartData.datasets[2].data = this.productionDataDefermentLabeling.data[2];
	  this.stopDataLoading();

	//   this.stopDataLoading();
	
	// this.chartData.datasets = [
	// {
	// 	label: "",
	// 	data: [],
	// 	borderColor: "#000000",
	// 	backgroundColor: "rgba(244,230,194,0.09)",
	// 	tension: 0,
	// 	fill: false,
	// 	pointRadius: 5,
	// 	pointHitRadius: 5,
	// 	pointHoverRadius: 5,
	// 	pointBorderColor: "#000000",
	// 	// pointHoverBackgroundColor: '#2CE6C2',
	// 	// pointHoverBorderColor: '#2CE6C2',
	// 	pointBackgroundColor: [] as any,
	// 	borderWidth: 1.5,
	// 	spanGaps: true
	// },
	// {
	// 	label: '',
	// 	data: [],
	// 	borderColor: 'transparent',
	// 	tension: 0,
	// 	borderWidth: 2,
	// 	fill: false,
	// 	pointRadius: 0,
	// 	pointHitRadius: 5,
	// 	pointHoverBorderColor: 'transparent',
	// 	pointHoverBackgroundColor: 'transparent',
	// 	yAxisID: 'y-axis-0',
	// 	spanGaps: true,
	// 	unit: '',
	// },
	// {
	// 	label: '',
	// 	data: [],
	// 	borderColor: 'transparent',
	// 	backgroundColor: 'rgba(244,230,194,0.25)',
	// 	tension: 0,
	// 	borderWidth: 2,
	// 	fill: '-1',
	// 	pointRadius: 0,
	// 	pointHitRadius: 5,
	// 	pointHoverBorderColor: 'transparent',
	// 	pointHoverBackgroundColor: 'transparent',
	// 	yAxisID: 'y-axis-0',
	// 	spanGaps: true,
	// 	unit: '',
	// },
	// ]








	  
  }

  async updatePrediction() {
    this.startDataLoading();
    const mappedLabels = this.selectedDates.map(i => ({
      date: i,
      OperatorNodeID: `${getConfigEnv("OPERATOR_PASCALED")}##${this.tasq.wellName}`,
      Include: true,
      key: `${getConfigEnv("OPERATOR_PASCALED")}##${this.tasq.wellName}##${this.tasq.defermentType}`,
      Operator: getConfigEnv("OPERATOR_PASCALED"),
      ModifiedBy: accountModule.user.email
    }));
    await defermentLabelingModule.updatePrediction({
      mappedLabels
    });
    this.stopDataLoading();
    this.$emit("update-prediction-finished");
  }

  toggleDateOnChart(date, skipRemoving = false) {
    // @ts-ignore
    const dateIndex = this.chartData.labels.indexOf(date);
    // @ts-ignore
    const foundIndex = this.selectedDates.indexOf(date);
    if (foundIndex !== -1 && !skipRemoving) {
      this.selectedDates.splice(foundIndex, 1);
      this.chartData.datasets[0].pointBackgroundColor[dateIndex] = "transparent";
    } else {
      if (!skipRemoving || foundIndex === -1) {
        // @ts-ignore
        this.selectedDates.push(date);
      }
      this.chartData.datasets[0].pointBackgroundColor[dateIndex] = "#2CE6C2";
    }
    (this.$refs.defermentChart as any).$data._chart.update();
  }

  enableDragging() {
    // @ts-ignore
    const { canvas } = this.$refs.defermentChart.$data._chart;
    // @ts-ignore
    const chart = this.$refs.defermentChart.$data._chart;
    const overlay = document.getElementById("overlay-deferment-labeling");
    if (!overlay) return;
    const { borderColor } = chart?.options?.annotation?.annotations[0] || {};
    let startIndex = 0;
    // @ts-ignore
    overlay.width = chart.width;
    // @ts-ignore
    overlay.height = chart.height;
    // @ts-ignore
    const selectionContext = overlay.getContext("2d");
    const selectionRect = {
      w: 0,
      startX: 0,
      startY: 0
    };
    selectionContext.fillStyle = borderColor || "rgba(255, 255, 255)";
    let drag = false;
    canvas.addEventListener("pointerdown", evt => {
      const points = chart.getElementsAtEventForMode(evt, "index", {
        intersect: false
      });
      startIndex = points[0]._index;
      const rect = canvas.getBoundingClientRect();
      selectionRect.startX = evt.clientX - rect.left;
      selectionRect.startY = chart.chartArea.top;
      drag = true;
    });
    canvas.addEventListener("pointermove", evt => {
      let rect = canvas.getBoundingClientRect();
      if (drag) {
        rect = canvas.getBoundingClientRect();
        selectionRect.w = evt.clientX - rect.left - selectionRect.startX;
        selectionContext.globalAlpha = 0.5;
        selectionContext.fillStyle = borderColor;
        selectionContext.clearRect(0, 0, canvas.width, canvas.height);
        selectionContext.fillRect(
          selectionRect.startX,
          selectionRect.startY,
          selectionRect.w,
          chart.chartArea.bottom - chart.chartArea.top
        );
      } else {
        selectionContext.clearRect(0, 0, canvas.width, canvas.height);
        const x = evt.clientX - rect.left;
        if (x > chart.chartArea.left) {
          selectionContext.fillRect(
            x,
            chart.chartArea.top,
            1,
            chart.chartArea.bottom - chart.chartArea.top
          );
        }
      }
    });
    canvas.addEventListener("pointerup", evt => {
      const points = chart.getElementsAtEventForMode(evt, "index", {
        intersect: false
      });
      drag = false;
      drag = false;
      const start = startIndex;
      let end = points[0]._index;
      if (Math.abs(start - end) >= 1) {
        end += 1;
      }
      const dates = this.chartData.labels.slice(start, end);
      dates.forEach(date => this.toggleDateOnChart(date, true));
    });
    canvas.addEventListener("pointerout", () => {
      selectionContext.clearRect(0, 0, canvas.width, canvas.height);
    });
  }
}


